import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import { ROUTES } from "../components/navigation/InternalRoute";
import LayoutCosmos from "../components/element/layout/LayoutCosmos";

const seo = {
  title: "Thanks - Oberion",
  description: null,
};
const heading = "Thanks for helping out!";
const subheading = ["Find out more about this project"];
const buttons = [
  <Button
    component={GatsbyLink}
    to={ROUTES.LIBRARY.TO}
    variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.LIBRARY.CAPTION}
  </Button>,
  <Button
    component={GatsbyLink}
    to={ROUTES.HOME.TO} variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.HOME.CAPTION}
  </Button>,
  <Button
    component={GatsbyLink}
    to={ROUTES.BLOG.TO}
    variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.BLOG.CAPTION}
  </Button>,
  <Button
    component={GatsbyLink}
    to={ROUTES.ABOUT.TO}
    variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.ABOUT.CAPTION}
  </Button>,
];

const Thanks = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);


export default Thanks;
